type Language = 'en' | 'es' | 'pt' | 'he';

type SectionUrls = {
  en: string;
  es?: string;
  pt?: string;
  he?: string;
};

type ValidSections =
  | 'home'
  | 'dunsNumber'
  | 'salesMarketing'
  | 'salesMarketingDuns'
  | 'thirdPartyRisk'
  | 'financeSolutions'
  | 'businessPromotion'
  | 'businessProtectionCreditPolicy'
  | 'businessProtectionCrossReference'
  | 'businessProtection'
  | 'dunsguide'
  | 'helpCenter';

const externalUrls: Record<ValidSections, SectionUrls> = {
  home: {
    en: 'https://www.cialdnb.com/',
    es: 'https://www.cialdnb.com/es/',
    pt: 'https://www.cialdnb.com/pt-br/',
  },
  dunsNumber: {
    en: 'https://www.cialdnb.com/d-u-n-s-number/',
    es: 'https://www.cialdnb.com/es/numero-d-u-n-s/',
    pt: 'https://www.cialdnb.com/pt-br/numero-d-u-n-s/',
  },
  salesMarketing: {
    en: 'https://www.cialdnb.com/sales-marketing/',
    es: 'https://www.cialdnb.com/es/ventas-y-marketing/',
    pt: 'https://www.cialdnb.com/pt-br/vendas-e-marketing/',
  },
  salesMarketingDuns: {
    en: 'https://en.cialdnb.com/products/duns-registered',
    es: 'https://es.cialdnb.com/productos/duns-registered',
    pt: 'https://pt.cialdnb.com/produtos/duns-registered',
  },
  thirdPartyRisk: {
    en: 'https://en.cialdnb.com/products/cial360-supplier',
    es: 'https://es.cialdnb.com/productos/cial360-supplier',
    pt: 'https://pt.cialdnb.com/produtos/cial360-supplier',
  },
  financeSolutions: {
    en: 'https://www.cialdnb.com/finance-solutions/business-information-report/',
    es: 'https://www.cialdnb.com/es/soluciones-financieras/business-information-report/',
    pt: 'https://www.cialdnb.com/pt-br/solucoes-financeiras/business-information-report/',
  },
  businessPromotion: {
    en: 'https://www.dbisrael.co.il/en/business-promotion/',
    he: 'https://www.dbisrael.co.il/business-promotion/',
  },
  businessProtectionCreditPolicy: {
    en: 'https://www.dbisrael.co.il/en/business-protection/credit-policy-management/ownership-holdings-tree-visualizer/',
    he: 'https://www.dbisrael.co.il/business-protection/credit-policy-management/ownership-holdings-tree-visualizer/',
  },
  businessProtectionCrossReference: {
    en: 'https://www.dbisrael.co.il/en/business-protection/credit-policy-management/cross-reference-report/',
    he: 'https://www.dbisrael.co.il/business-protection/credit-policy-management/cross-reference-report/',
  },
  businessProtection: {
    en: 'https://www.dbisrael.co.il/en/business-protection/',
    he: 'https://www.dbisrael.co.il/business-protection/',
  },
  dunsguide: {
    en: 'https://www.dunsguide.com',
    es: 'https://www.dunsguide.com/es',
    pt: 'https://www.dunsguide.com/pt',
  },
  helpCenter: {
    en: 'https://help.cialdnb.com/en/dunsguide',
    es: 'https://help.cialdnb.com/es/dunsguide',
    pt: 'https://help.cialdnb.com/pt-br/dunsguide',
  },
};

function getExternalUrl({
  section,
  language,
  addSource = false,
}: {
  section: ValidSections;
  language: Language;
  addSource?: boolean;
}): string {
  const urlObject = externalUrls[section];

  if (urlObject) {
    const finalUrl = urlObject[language] || urlObject.en;

    return `${finalUrl}${addSource ? '?utm_source=dunsguide' : ''}`;
  }

  return '#';
}

export { getExternalUrl };
