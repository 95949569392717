/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createApi, BaseQueryFn, defaultSerializeQueryArgs } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { omit as _omit } from 'lodash';
import { HYDRATE } from 'next-redux-wrapper';
import queryString from 'query-string';
import { toast } from 'react-toastify';

import { SearchClick } from '@app/library/models';
import { IUpdateCompanySettingsDTO, IUpdateCompanySettingsResponseDTO } from '@shared-interfaces';
import { DunsguideFile, Workflow } from 'store/models/Workflow';
import { getCurrentCompany } from 'utils/company';
import { getLanguageFromPathName, getLocale } from 'utils/locale';

import CookieNames from 'constants/cookieNames';
import { LANGUAGE_HEADER_KEY } from 'constants/language';

import {
  CreateReviewDisputeDto,
  CreateReviewDto,
  PaginationOptions,
  TopSearches,
  TotalViewsChartDto,
  EditCompanyDTO,
  EmailInputDto,
  CreatePersonRequestDto,
  UpdatePersonRequestDto,
  CompanyPersonBatchUpdateRequestDto,
  GalleryDto,
  UpdateGalleriesRequestDto,
  CreateInfographicRequestDto,
  PartialInfographicDto,
  UpdateInfographicRequestDto,
  SignupRequestDto,
  OnboardingAutomaticVerificationRequestDto,
  SearchBreadcrumbsArgs,
  SearchCompanyArgs,
  CreateClaimCiecRequestDto,
  BuroReportRequestDto,
  ClaimRequestDto,
  SubmitSelectedPlanBodyDto,
  CreateWorkflowBodyDto,
  SelectCompanyForDunsRequestBodyDto,
  OnboardingVerificationBaseRequestDto,
  OnboardingDataProfileRequestDto,
  GetRequiredDocumentsRequestBodyDto,
  ActivePlanRequestBodyDto,
  ActivePlanResponseBodyDto,
} from './apiRequest';
import {
  IDunsguideCompanyReviewStatistics,
  PaginationResultInterface,
  ReviewDto,
  ReplyDto,
  CreatePersonResponseDto,
  PersonResponseDto,
  DeleteResult,
  GetSignatureResponseDto,
  GetClaimResponseDto,
  SearchCompanyResponse,
  AdditionalDocumentDto,
  BreadcrumbDto,
  CompanyGalleryDto,
  BuroReportResponseDto,
  ClaimResponseDto,
  TaskDto,
  GetRequiredDocumentsResponseDto,
} from './apiResponse';
import { Company } from './models/Company';
import { CompanyGalleryItem } from './models/CompanyGallery';
import { FavoriteDto } from './models/Favorite';
import {
  GetIndustryByIdQueryArgs,
  GetSuggestedIndustriesQueryArgs,
  Industry,
  IndustryPaginationQueryArgs,
  Language,
  Sector,
  Subject,
} from './models/Industry';
import {
  GetLocationByCodeQueryArgs,
  GetLocationByIdQueryArgs,
  GetSuggestedLocationsQueryArgs,
  Location,
  LocationPaginationQueryArgs,
} from './models/Location';
import { OrderReportResponseDto } from './models/OrderReport';
import { ReviewDispute } from './models/ReviewDispute';
import { SatReport } from './models/SatReport';
import { Search } from './models/Search';
import { FileUploadStatus } from './models/Task';
import {
  CloudinaryFolders,
  CreateClaimCiecResponseDto,
  CreateClaimResponseDto,
  CredentialsValidationResponseStatus,
  StatisticType,
} from './models/types';
import { UpdateUserDTO, User } from './models/User';

const BASE_API_URL = 'api';
const BASE_API_AUTHORIZATION_URL = 'api/api';

const CACHE_TTL_ONE_DAY = 60 * 60 * 24; // Cache for one day

const API_URL = process.env.API_URL;
const AXIOS_TIMEOUT = process.env.NEXT_PUBLIC_TIMEOUT;

export class PaginationQueryArgs {
  size?: number;
  page?: number;
}

function getUrlForSSROrNavigateToNextServer(url: string, baseUrl: string, headers: Record<string, string> = {}) {
  const sanitizedUrl = url.replace(/^api\//, '');
  // it indicates that we are fetching endpoints on the server side using getServerSideProps.
  // In this case, we can make a direct backend call instead of navigating to the Next.js server.
  // The getServerSideProps function is executed on the server-side when the page is requested,
  // and it doesn't utilize the Next.js API server for handling this specific API route
  // refetch calls that happen cause of `invalidatesTags` will point to server if they were called during getServerSideProps
  // with `typeof window === 'undefined'` we are making sure they go through Next.js server
  // TODO maybe we don't need `isSSRCall` flag and checking for window is enough
  const isFetchingUserOnServer = typeof window === 'undefined' && headers.isSSRCall === 'true';

  // Determine the appropriate URL based on whether we need to call the backend or navigate to the Next.js API server
  const urlForSSROrProxy = isFetchingUserOnServer
    ? `${API_URL}/${sanitizedUrl}` // Backend call directly in getServerSideProps
    : `${baseUrl}${url}`; // Navigating to the Next.js API server

  return urlForSSROrProxy;
}

interface UserArg {
  headers?: Record<string, string>;
  context?: any;
}

// there is no way to get locale information in Next.js api route
// instead we read it from the window and attach it to header so Next.js API server can read it
function getLanguage() {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);

    const { language } = getLocale({ language: getLanguageFromPathName(url.pathname) });
    return language;
  }

  return undefined;
}

// sanitize internal headers and pass only whats required to API
function sanitizeHeaders(headers = {}) {
  return _omit(headers, ['isSSRCall']);
}

interface AxiosQueryArgs {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  data?: any;
  headers?: Record<string, string>;
  context?: any;
}

const axiosBaseQuery: BaseQueryFn<AxiosQueryArgs, any, { message?: string; status?: number }> = async ({
  url,
  method,
  data,
  headers = {},
  context = {},
}) => {
  const language = context?.language || getLanguage();
  const sanitizedUrl = getUrlForSSROrNavigateToNextServer(url, '/', headers);

  const getToken = () => {
    if (typeof window !== 'undefined') {
      return Cookies.get(CookieNames.token);
    }
    if (context?.token) {
      return context.token;
    }
    return null;
  };

  try {
    const result = await axios({
      url: sanitizedUrl,
      method,
      data,
      timeout:
        sanitizedUrl.includes('verify-company') || sanitizedUrl.includes('create-claim-ciec')
          ? 120000
          : Number(AXIOS_TIMEOUT),
      headers: sanitizeHeaders({
        ...headers,
        ...(language && { [LANGUAGE_HEADER_KEY]: language }),
        ...(getToken() && { Authorization: `Bearer ${getToken()}` }),
      }),
    });
    return { data: result.data }; // Success path
  } catch (error) {
    if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') {
      // Assuming `toast.error` is synchronous and doesn't affect return value
      toast.error(`${error.response?.data?.message || error.response?.data?.error?.message}` || 'unknown error', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const errorData = _omit(error?.response?.data || {}, ['message']);
    // Always return an error object, never `undefined`
    return {
      error: {
        status: error.response?.status ?? 'Network Error',
        message: error.response?.data?.message || error.response?.data?.error?.message || error.message,
        ...errorData,
      },
    };
  }
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Company', 'Person', 'Gallery', 'Review', 'User', 'Favorite', 'Infographic', 'AdminReview', 'Users'],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
    return null;
  },
  endpoints: (builder) => ({
    getUser: builder.query<User, UserArg | typeof USER_CACHE_KEY>({
      query: (arg) => {
        // Default values for headers and context
        const { headers = {}, context = {} } = typeof arg === 'object' ? arg : {};
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/users/me`,
          method: 'GET',
          headers,
          context,
        };
      },
      serializeQueryArgs: () => {
        // Generate a unique cache key to sync redux stores during hydration
        return USER_CACHE_KEY;
      },
      transformResponse: (response) => {
        if (typeof window !== 'undefined') {
          // Retrieve and set the current company for client-side rendering.
          const currentCompany = getCurrentCompany(response, Cookies.get(CookieNames.currentCompanyId));
          // When the user is refetched or the /me route is called, preserve currentCompany in the client-side response.
          return { ...response, currentCompany: currentCompany };
        }
        // For server-side rendering (SSR) fetching, return the response to be later extended with current company information.
        // Note: Further extension of the response occurs in enhanceServerSideProps.
        return response;
      },
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<UpdateUserDTO, UpdateUserDTO>({
      query: (data) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/users/me`,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: ['User'],
    }),
    updateUserImage: builder.mutation<void, FormData>({
      query: (data) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/users/avatar`,
          method: 'POST',
          data,
          headers: { 'Content-Type': 'multipart/form-data' },
        };
      },
      invalidatesTags: ['User'],
    }),
    getFavorites: builder.query<PaginationResultInterface<FavoriteDto[]>, PaginationOptions>({
      query: ({ size = 5, page = 1 }) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/favorites/list?size=${size}&page=${page}`,
          method: 'GET',
        };
      },
      providesTags: ['Favorite'],
    }),
    getFavoritesIds: builder.query<PaginationResultInterface<{ companyId: string; id: string }>, void>({
      query: () => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/favorites/ids`,
          method: 'GET',
        };
      },
      providesTags: ['Favorite'],
    }),
    createFavorite: builder.mutation<FavoriteDto, { id: string }>({
      query: ({ id: companyId }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/favorites`,
        method: 'POST',
        data: { companyId },
      }),
      invalidatesTags: ['Favorite'],
    }),
    destroyFavorite: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/favorites/${id}`,
        method: 'DELETE',
        data: { id },
      }),
      invalidatesTags: ['Favorite'],
    }),
    getCompany: builder.query<
      Company,
      { isLoggedIn?: boolean; duns: string; language: string; headers?: Record<string, any> }
    >({
      query: (params) => {
        const { headers = {}, duns, isLoggedIn } = params;
        return {
          url: `${isLoggedIn ? BASE_API_AUTHORIZATION_URL : BASE_API_URL}/companies/${duns}`,
          method: 'GET',
          headers: { ...headers },
        };
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        const { duns, language } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: { duns, language },
          endpointDefinition,
        });
      },
      providesTags: (result) => {
        const people: { type: 'Person'; id: string }[] = result?.people
          ? result?.people?.map(({ id }) => ({ type: 'Person', id }))
          : [];
        const gallery: { type: 'Gallery'; id: string }[] = result?.gallery
          ? result?.gallery?.map(({ id }) => ({ type: 'Gallery', id }))
          : [];
        const infographic: { type: 'Infographic'; id: string }[] = result?.infographics
          ? result?.infographics?.map(({ id }) => ({ type: 'Infographic', id }))
          : [];
        return result
          ? [...people, ...gallery, ...infographic, { type: 'Company', id: result.id }]
          : [{ type: 'Company' }];
      },
    }),
    getReviewStatistics: builder.query<
      IDunsguideCompanyReviewStatistics,
      { duns: string; headers?: Record<string, any> }
    >({
      query: (params) => {
        const { headers = {}, duns } = params;
        return {
          url: `${BASE_API_URL}/reviews/companies/${duns}/review-statistics`,
          method: 'GET',
          headers: { ...headers },
        };
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        const { duns } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: { duns },
          endpointDefinition,
        });
      },
    }),
    updateCompany: builder.mutation<void, { id: string; data: EditCompanyDTO }>({
      query: ({ id, data }) => {
        // Check and set each specific social media field to null if it's an empty string
        data.website = data.website === '' ? null : data.website;
        data.facebook = data.facebook === '' ? null : data.facebook;
        data.tiktok = data.tiktok === '' ? null : data.tiktok;
        data.instagram = data.instagram === '' ? null : data.instagram;
        data.twitter = data.twitter === '' ? null : data.twitter;
        data.linkedin = data.linkedin === '' ? null : data.linkedin;
        data.youtube = data.youtube === '' ? null : data.youtube;

        return {
          url: `${BASE_API_AUTHORIZATION_URL}/companies/${id}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: ['Company', 'User'],
    }),
    getProfileViews: builder.query<{ totalViews: string }, string>({
      query: (id) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/analytics/${id}/view/total-views`,
        method: 'GET',
      }),
    }),
    getProfileViewsChart: builder.query<TotalViewsChartDto, string>({
      query: (id) => {
        return {
          url: `${BASE_API_URL}/analytics/${id}/view/total-views-chart`,
          method: 'GET',
        };
      },
    }),
    getSearchImpressions: builder.query<{ totalSearchImpressions: string }, string>({
      query: (id) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/analytics/${id}/search/total-search-impressions`,
          method: 'GET',
        };
      },
    }),
    getSearchImpressionsChart: builder.query<TotalViewsChartDto, string>({
      query: (id) => {
        return {
          url: `${BASE_API_URL}/analytics/${id}/search/total-search-impressions-chart`,
          method: 'GET',
        };
      },
    }),
    getSearchClicks: builder.query<{ totalSearchClicks: string }, string>({
      query: (id) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/analytics/${id}/search/total-search-clicks`,
          method: 'GET',
        };
      },
    }),
    getSearchClicksChart: builder.query<TotalViewsChartDto, string>({
      query: (id) => {
        return {
          url: `${BASE_API_URL}/analytics/${id}/search/total-search-clicks-chart`,
          method: 'GET',
        };
      },
    }),
    getSearch: builder.query<{ items: Search[] }, TopSearches>({
      query: ({ id, page = 1, size = 10, sortFirst = 'averagerank,asc', sortSecond = 'impressions,desc' }) => {
        return {
          url: `${BASE_API_URL}/analytics/${id}/search/top-searches?size=${size}&page=${page}&sort=${sortFirst}&sort=${sortSecond}`,
          method: 'GET',
        };
      },
    }),
    addTrackClick: builder.mutation<SearchClick, { id: string; searchId: string }>({
      query: ({ id, searchId }) => {
        return {
          url: `${BASE_API_URL}/analytics/${id}/search/track-click`,
          method: 'POST',
          data: { searchId },
        };
      },
    }),
    getReviewsByUser: builder.query<
      PaginationResultInterface<ReviewDto[]>,
      { page?: number; size?: number; status: string }
    >({
      query: ({ page = 1, size = 3, status }) => {
        const params = new URLSearchParams();
        params.append('size', String(size));
        params.append('page', String(page));
        if (status) {
          params.append('status', status);
        }
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/reviews?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: ['Review'],
    }),
    addReviewByUser: builder.mutation<ReviewDto, { data: CreateReviewDto }>({
      query: ({ data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/reviews`,
        method: 'POST',
        data: {
          duns: data.duns,
          text: data.text,
          rating: parseInt(data.rating.toString(), 10),
        },
      }),
      invalidatesTags: ['Review'],
    }),
    deleteReviewByUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/reviews/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Review'],
    }),
    getPublicCompanyReviews: builder.query<
      PaginationResultInterface<ReviewDto>,
      { id: string; page?: number; size?: number; status?: string }
    >({
      query: ({ id, page = 1, size = 3, status }) => {
        const params = new URLSearchParams();
        params.append('size', String(size));
        params.append('page', String(page));
        if (status) {
          params.append('status', status);
        }
        return {
          url: `${BASE_API_URL}/reviews/companies/${id}?${params.toString()}`,
          method: 'GET',
        };
      },
    }),
    getAdminCompanyReviews: builder.query<
      PaginationResultInterface<ReviewDto>,
      { id: string; page?: number; size?: number; status?: string }
    >({
      query: ({ id, page = 1, size = 3, status }) => {
        const params = new URLSearchParams();
        params.append('size', String(size));
        params.append('page', String(page));
        if (status) {
          params.append('status', status);
        }
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/company-admin/${id}/reviews?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: ['Review'],
    }),
    deleteReplyByAdmin: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/reviews/reply/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Review'],
    }),
    replyReview: builder.mutation<ReplyDto, { id: string; data: { text: string } }>({
      query: ({ id, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/reviews/${id}/reply`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Review'],
    }),
    addDisputeByAdmin: builder.mutation<ReviewDispute, { companyId: string; id: string; data: CreateReviewDisputeDto }>(
      {
        query: ({ companyId, id, data }) => ({
          url: `${BASE_API_AUTHORIZATION_URL}/company-admin/${companyId}/reviews/${id}/dispute`,
          method: 'POST',
          data: {
            reason: data?.reason,
            explanation: data?.explanation,
          },
        }),
        invalidatesTags: ['Review'],
      },
    ),
    deleteDisputeByAdmin: builder.mutation<void, { companyId: string; id: string }>({
      query: ({ companyId, id }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/company-admin/${companyId}/reviews/dispute/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Review'],
    }),
    collectReviewsByAdmin: builder.mutation<void, { companyId: string; data: EmailInputDto }>({
      query: ({ companyId, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/company-admin/${companyId}/reviews/collect-reviews`,
        method: 'POST',
        data: {
          emails: data?.emails,
          language: data?.language,
          redirectUrl: data?.redirectUrl,
        },
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Review'],
    }),
    getLanguages: builder.query<Language[], void>({
      query: () => {
        return {
          url: `${BASE_API_URL}/utils/languages`,
          method: 'GET',
        };
      },
    }),
    addPerson: builder.mutation<
      CreatePersonResponseDto,
      { id: string; language: string; data: CreatePersonRequestDto }
    >({
      query: ({ id, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/persons/${id}`,
        method: 'POST',
        data: {
          translations: data?.translations,
          image: data?.image,
        },
      }),
      invalidatesTags: ['Company'],
      // This query should update the gallery company
      async onQueryStarted({ id, language }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedItemPerson } = await queryFulfilled;
          const person = {
            ...updatedItemPerson,
            name: updatedItemPerson?.translations[0]?.name,
            title: updatedItemPerson?.translations[0]?.title,
            description: updatedItemPerson?.translations[0]?.description,
          };
          dispatch(
            api.util.updateQueryData('getCompany', { duns: id, language }, (company) => {
              Object.assign(company.people, [...company.people, person]);
            }),
          );
        } catch {}
      },
    }),
    editPerson: builder.mutation<PersonResponseDto, { id: string; companyId: string; data: UpdatePersonRequestDto }>({
      query: ({ id, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/persons/${id}`,
        method: 'PATCH',
        data,
      }),
      // invalidatesTags: (result) => [{ type: 'Person', id: result.id }],
    }),
    editPersonList: builder.mutation<
      { persons: PersonResponseDto[] },
      { companyId: string; language: string; data: CompanyPersonBatchUpdateRequestDto }
    >({
      query: ({ data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/persons/batch`,
        method: 'PATCH',
        data: { personsUpdates: data },
      }),
      // This query should update the gallery company
      async onQueryStarted({ companyId, language, data }, { dispatch, queryFulfilled }) {
        const editGallery = dispatch(
          api.util.updateQueryData('getCompany', { duns: companyId, language }, (company) => {
            Object.assign(company.people, data);
          }),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          editGallery.undo();
        }
      },
    }),
    deletePerson: builder.mutation<DeleteResult, { id: string }>({
      query: ({ id }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/persons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Person' }],
      // This query should update the gallery company
      /* async onQueryStarted({ id, companyId }, { dispatch, queryFulfilled }) {
        const removePeopleItem = dispatch(
          api.util.updateQueryData('getCompany', companyId, (company) => {
            const newPeople = company.people.filter((item) => item.id !== id);
            Object.assign(company.people, newPeople);
          }),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          removePeopleItem.undo();
        }
      }, */
    }),
    addGalleryItem: builder.mutation<CompanyGalleryDto, { id: string; language: string; data: GalleryDto }>({
      query: ({ id, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/galleries/${id}`,
        method: 'POST',
        data: {
          image: data?.image,
          video: data?.video,
          title: data?.title,
          order: data?.order,
          type: data?.type,
        },
      }),
      // This query should update the gallery company
      async onQueryStarted({ id, language }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedItemGallery } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getCompany', { duns: id, language }, (company) => {
              const newGallery = [...company.gallery];
              newGallery.push({
                id: updatedItemGallery.id,
                image: updatedItemGallery.image,
                video: updatedItemGallery.video,
                title: updatedItemGallery.title,
                type: updatedItemGallery.type,
                order: updatedItemGallery.order,
              });
              Object.assign(company.gallery, newGallery);
            }),
          );
        } catch {}
      },
    }),
    editGalleryItem: builder.mutation<
      CompanyGalleryItem,
      { id: string; companyId: string; language: string; data: GalleryDto }
    >({
      query: ({ id, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/galleries/${id}`,
        method: 'PATCH',
        data,
      }),
      // This query should update the gallery company
      async onQueryStarted({ id, companyId, language, data }, { dispatch, queryFulfilled }) {
        const editGalleryItem = dispatch(
          api.util.updateQueryData('getCompany', { duns: companyId, language }, (company) => {
            const galleryItem = company?.gallery?.find((item) => item.id === id);
            if (galleryItem) {
              Object.assign(galleryItem, data);
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          editGalleryItem.undo();
        }
      },
    }),
    editGalleryList: builder.mutation<
      CompanyGalleryItem[],
      { id: string; language: string; data: UpdateGalleriesRequestDto }
    >({
      query: ({ data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/galleries/batch`,
        method: 'PATCH',
        data,
      }),
      // This query should update the gallery company
      async onQueryStarted({ id, language, data }, { dispatch, queryFulfilled }) {
        const editGallery = dispatch(
          api.util.updateQueryData('getCompany', { duns: id, language }, (company) => {
            Object.assign(company.gallery, data.galleries);
          }),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          editGallery.undo();
        }
      },
    }),
    deleteGalleryItem: builder.mutation<DeleteResult, { id: string }>({
      query: ({ id }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/galleries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: 'Gallery' }],
      // This query should update the gallery company
      /* async onQueryStarted({ id, data }, { dispatch, queryFulfilled }) {
        const removeGalleryItem = dispatch(
          api.util.updateQueryData('getCompany', id, (company) => {
            const newGallery = company.gallery.filter((item) => item.id !== data.id);
            Object.assign(company.gallery, newGallery);
          }),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          removeGalleryItem.undo();
        }
      }, */
    }),
    addStatistic: builder.mutation<
      { graphInfographics: PartialInfographicDto[] },
      { id: string; data: CreateInfographicRequestDto }
    >({
      query: ({ id, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/infographics`,
        method: 'POST',
        data: {
          duns: id,
          graphInfographics: data,
        },
      }),
      invalidatesTags: ['Infographic', 'Company'],
      // This query should update the gallery company
      /* async onQueryStarted({ id, data }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedItemInfographic } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getCompany', id, (company) => {
              const newInfographics = [...company.infographics];
              newInfographics.push(...updatedItemInfographic.graphInfographics);
              Object.assign(company.infographics, newInfographics);
            }),
          );
        } catch {}
      }, */
    }),
    editStatistic: builder.mutation<
      { graphInfographics: PartialInfographicDto[] },
      { id: string; data: UpdateInfographicRequestDto }
    >({
      query: ({ id, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/infographics/batch`,
        method: 'PATCH',
        data: {
          duns: id,
          graphInfographics: data,
        },
      }),
      invalidatesTags: ['Infographic'],
      // This query should update the gallery company
      /* async onQueryStarted({ id, data }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedItemInfographic } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData('getCompany', id, (company) => {
              Object.assign(company.infographics, updatedItemInfographic.graphInfographics);
            }),
          );
        } catch {}
      }, */
    }),
    deleteStatistic: builder.mutation<void, { id: string; type: StatisticType }>({
      query: ({ id, type }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/infographics`,
        method: 'DELETE',
        data: {
          duns: id,
          type,
        },
      }),
      invalidatesTags: ['Infographic'],
      // This query should update the gallery company
      /* async onQueryStarted({ id, type }, { dispatch, queryFulfilled }) {
        const deleteInfographic = dispatch(
          api.util.updateQueryData('getCompany', id, (company) => {
            const newInfographics = [...company.infographics];
            const infographicList = [];
            newInfographics?.forEach((graph) => {
              if (graph.type !== type) infographicList.push(graph);
            });
            Object.assign(newInfographics, infographicList);
          }),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          deleteInfographic.undo();
        }
      }, */
    }),
    getSignature: builder.mutation<GetSignatureResponseDto, CloudinaryFolders>({
      query: (type) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/cloudinary/${type}/signature`,
          method: 'GET',
        };
      },
    }),
    signUpForm: builder.mutation<void, { data: SignupRequestDto; headers: { recaptcha: string } }>({
      query: ({ data, headers }) => ({
        url: `${BASE_API_URL}/users/signup`,
        method: 'POST',
        data,
        headers,
      }),
    }),
    claimUploadDocuments: builder.mutation<CreateClaimResponseDto, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/claims/${id}/create-claim`,
        method: 'POST',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: ['User'],
    }),
    getClaims: builder.query<GetClaimResponseDto, { onlyPending: boolean; isMissingSetup: boolean }>({
      query: () => ({
        url: `${BASE_API_AUTHORIZATION_URL}/claims/claims`,
        method: 'GET',
      }),
    }),
    verifyOnboardingCompany: builder.mutation<
      { status: CredentialsValidationResponseStatus },
      OnboardingAutomaticVerificationRequestDto
    >({
      query: ({ aclCompanyId, workflowId, ciec, rfc }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/onboarding/verify-company`,
        method: 'POST',
        data: {
          ciec,
          rfc,
          aclCompanyId,
          workflowId,
        },
      }),
      invalidatesTags: ['User'],
    }),
    onboardingUploadDocuments: builder.mutation<void, OnboardingVerificationBaseRequestDto & { data: FormData }>({
      query: ({ aclCompanyId, workflowId, data }) => {
        if (aclCompanyId) {
          data.append('aclCompanyId', aclCompanyId);
        }
        if (workflowId) {
          data.append('workflowId', workflowId);
        }
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/onboarding/required-docs`,
          method: 'POST',
          data: data,
          headers: { 'Content-Type': 'multipart/form-data' },
        };
      },
      invalidatesTags: ['User'],
    }),
    uploadCompanyForm: builder.mutation<
      DunsguideFile[],
      OnboardingDataProfileRequestDto & { data: { attachment: DunsguideFile[] } }
    >({
      query: ({ aclCompanyId, workflowId, data }) => {
        const formData = new FormData();

        // Append files and other data to the FormData object
        data.attachment
          .sort((a, b) => a.index - b.index)
          .forEach((file) => {
            formData.append(`attachment[${file.index}]`, file);
            formData.append(`attachments[${file.index}][name]`, file.name);
            formData.append(`attachments[${file.index}][index]`, file.index.toString());
            formData.append(`attachments[${file.index}][status]`, FileUploadStatus.PENDING);
          });

        if (aclCompanyId) {
          formData.append('aclCompanyId', aclCompanyId);
        }
        if (workflowId) {
          formData.append('workflowId', workflowId);
        }
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/onboarding/company-form/upload`,
          method: 'POST',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        };
      },
      invalidatesTags: ['User'],
    }),
    getAdditionalDocuments: builder.query<{ additionalDocuments: AdditionalDocumentDto }, string>({
      query: (country) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/onboarding/${country}/additional-documents`,
        method: 'GET',
      }),
    }),
    createClaimCiec: builder.mutation<CreateClaimCiecResponseDto, CreateClaimCiecRequestDto>({
      query: ({ id, ciec, rfc, redirectUrl }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/claims/${id}/create-claim-ciec`,
        method: 'POST',
        data: {
          ciec,
          rfc,
          redirectUrl,
        },
      }),
    }),
    createClaim: builder.mutation<ClaimResponseDto, ClaimRequestDto>({
      query: (data) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/claims`,
        method: 'POST',
        data,
      }),
    }),
    selectPlan: builder.mutation<void, SubmitSelectedPlanBodyDto>({
      query: ({ selectedPlan, workflowId }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/plans/submit-selected-plan`,
        method: 'POST',
        data: { workflowId, selectedPlan },
      }),
    }),
    activePlan: builder.query<ActivePlanResponseBodyDto, ActivePlanRequestBodyDto>({
      query: (data) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/plans/active-plan`,
        method: 'POST',
        data,
      }),
    }),
    // Industries
    getIndustryById: builder.query<Industry, GetIndustryByIdQueryArgs>({
      query: ({ headers = {}, industryId, parentsLevels, childrenLevels }: GetIndustryByIdQueryArgs) => {
        const params = new URLSearchParams();
        if (parentsLevels) {
          params.append('parentsLevels', parentsLevels);
        }
        if (childrenLevels) {
          params.append('childrenLevels', childrenLevels);
        }
        return {
          url: `${BASE_API_URL}/industries/${industryId}?${params.toString()}`,
          headers: { ...headers },
          method: 'GET',
        };
      },
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    startIdentityVerification: builder.mutation({
      query: ({ redirectUrl }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/identity-verification`,
        method: 'POST',
        data: { redirectUrl },
      }),
    }),
    checkIdentityVerificationStatus: builder.query({
      query: () => ({
        url: `${BASE_API_AUTHORIZATION_URL}/identity-verification`,
        method: 'GET',
      }),
    }),
    getIndustries: builder.query({
      query: (args) => ({
        url: `${BASE_API_URL}/industries/subjects?size=${args.size}`,
        params: { language: args.language },
        method: 'GET',
      }),
    }),
    getSuggestedIndustries: builder.query<Array<Industry>, GetSuggestedIndustriesQueryArgs>({
      query: (params: GetSuggestedIndustriesQueryArgs) => ({
        url: `${BASE_API_URL}/industries?${queryString.stringify(params)}`,
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
    }),
    getSectorIndustries: builder.query<PaginationResultInterface<Sector>, IndustryPaginationQueryArgs>({
      query: ({ headers = {}, page = 1, size = 10, language }: IndustryPaginationQueryArgs) => ({
        url: `${BASE_API_URL}/industries/sectors?${queryString.stringify(
          { page, size, language },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true },
        )}`,
        headers: { ...headers },
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    getSubjectIndustries: builder.query<PaginationResultInterface<Subject>, IndustryPaginationQueryArgs>({
      query: ({ page = 1, size = 10, language }: IndustryPaginationQueryArgs) => ({
        url: `${BASE_API_URL}/industries/subjects?${queryString.stringify(
          { page, size, language },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true },
        )}`,
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
    }),
    getIndustriesByParent: builder.query<PaginationResultInterface<Industry>, IndustryPaginationQueryArgs>({
      query: ({ headers = {}, parentId = 0, page = 1, size = 10, language }: IndustryPaginationQueryArgs) => ({
        url: `${BASE_API_URL}/industries/by-parent/${parentId}?${queryString.stringify(
          { page, size, language },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true },
        )}`,
        headers: { ...headers },
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    // Locations
    getLocationById: builder.query<Location, GetLocationByIdQueryArgs>({
      query: ({ locationId, headers = {}, ...queryArgs }: GetLocationByIdQueryArgs) => ({
        url: `${BASE_API_URL}/locations/${locationId}?${queryString.stringify(queryArgs)}`,
        headers: { ...headers },
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    getLocationByCode: builder.query<Location, GetLocationByCodeQueryArgs>({
      query: ({ locationCode, headers = {} }: GetLocationByCodeQueryArgs) => ({
        url: `${BASE_API_URL}/locations/by-iso-code/${locationCode}`,
        headers: { ...headers },
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    getSuggestedLocations: builder.query<Location[], GetSuggestedLocationsQueryArgs>({
      query: (params: GetSuggestedLocationsQueryArgs) => ({
        url: `${BASE_API_URL}/locations?${queryString.stringify(params)}`,
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
    }),
    getAllLocations: builder.query<PaginationResultInterface<Location>, LocationPaginationQueryArgs>({
      query: ({
        headers = {},
        type,
        page = 1,
        size = 10,
        withChildren = false,
        language,
        slug = null,
      }: LocationPaginationQueryArgs) => ({
        url: `${BASE_API_URL}/locations/all?${queryString.stringify(
          { type, page, size, withChildren, language, slug },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true },
        )}`,
        headers: { ...headers },
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    getAllCountries: builder.query<[Location[]], { extended: boolean }>({
      query: ({ extended = false }: { extended?: boolean } = {}) => {
        const params = new URLSearchParams();
        if (extended) {
          params.append('extended', extended.toString());
        }
        return {
          url: `${BASE_API_URL}/locations/countries?${params.toString()}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
    }),
    getLocationsByParent: builder.query<PaginationResultInterface<Location>, LocationPaginationQueryArgs>({
      query: ({ headers = {}, parentId, page = 1, size = 10, language }: LocationPaginationQueryArgs) => ({
        url: `${BASE_API_URL}/locations/by-parent/${parentId}?${queryString.stringify(
          { page, size, language },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true },
        )}`,
        headers: { ...headers },
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    // Search Companies
    getSearchByParams: builder.query<SearchCompanyResponse, SearchCompanyArgs>({
      query: (params: SearchCompanyArgs) => {
        const { headers = {}, isLoggedIn, ...restParams } = params;
        const requestUrl = `/search?${queryString.stringify(restParams, { arrayFormat: 'bracket' })}`;
        return {
          url: `${isLoggedIn ? BASE_API_AUTHORIZATION_URL : BASE_API_URL}${requestUrl}`,
          headers: { ...headers },
          method: 'GET',
        };
      },
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    // breadcrumbs
    getBreadcrumbs: builder.query<BreadcrumbDto[], SearchBreadcrumbsArgs>({
      query: (params: SearchBreadcrumbsArgs) => {
        const { headers = {}, ...restParams } = params;
        const requestUrl = `/search/breadcrumbs?${queryString.stringify(restParams)}`;
        return {
          url: `${BASE_API_URL}${requestUrl}`,
          headers: { ...headers },
          method: 'GET',
        };
      },
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        const { headers, ...restQueryArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs: restQueryArgs,
        });
      },
    }),
    // Pulso
    getPulsoTasks: builder.query<TaskDto[], { aclCompanyId: string }>({
      query: ({ aclCompanyId }: { aclCompanyId: string }) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/companies/${aclCompanyId}/pulso-tasks`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
    }),
    getOrderReport: builder.query<OrderReportResponseDto, { aclCompanyId: string; orderReportId: string }>({
      query: ({ aclCompanyId, orderReportId }: { aclCompanyId: string; orderReportId: string }) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/companies/${aclCompanyId}/order/reports/${orderReportId}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
    }),
    getSatReport: builder.query<SatReport, { aclCompanyId: string; satReportId: string }>({
      query: ({ aclCompanyId, satReportId }: { aclCompanyId: string; satReportId: string }) => {
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/companies/${aclCompanyId}/sat/reports/${satReportId}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
    }),
    getBuroReport: builder.query<BuroReportResponseDto, BuroReportRequestDto>({
      query: ({ aclCompanyId, buroReportId }: BuroReportRequestDto) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/companies/${aclCompanyId}/buro/reports/${buroReportId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: CACHE_TTL_ONE_DAY,
      serializeQueryArgs: ({ queryArgs, endpointName, endpointDefinition }) => {
        return defaultSerializeQueryArgs({
          endpointName,
          endpointDefinition,
          queryArgs,
        });
      },
    }),
    // TODO add return type
    requestSatAuthorization: builder.mutation<any, { id: string; ciec: string; rfc: string }>({
      query: ({ id, ciec, rfc }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/companies/${id}/sat/authorization`,
        method: 'POST',
        data: {
          ciec,
          rfc,
        },
      }),
    }),
    createWorkflow: builder.mutation<Workflow, CreateWorkflowBodyDto>({
      query: (data) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/workflows`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['User'],
    }),
    // Workflows
    getWorkflow: builder.query<Workflow, { workflowId: string; headers?: Record<string, any> }>({
      query: (params) => {
        const { headers = {}, workflowId } = params;
        return { url: `${BASE_API_AUTHORIZATION_URL}/workflows/${workflowId}`, method: 'GET', headers: { ...headers } };
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        const { workflowId } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: { workflowId },
          endpointDefinition,
        });
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: newWorkflow } = await queryFulfilled;

          function mergeWorkflows(existingWorkflows: Workflow[], newWorkflow: Workflow): Workflow[] {
            const workflowIndex = existingWorkflows.findIndex((wf) => wf.id === newWorkflow.id);
            if (workflowIndex > -1) {
              existingWorkflows[workflowIndex] = newWorkflow;
            }
            return existingWorkflows;
          }

          dispatch(
            api.util.updateQueryData('getUser', USER_CACHE_KEY, (draft: User) => {
              // Update user workflows
              draft.workflows = mergeWorkflows(draft.workflows, newWorkflow);

              // Update each company's workflows
              draft.companies = draft.companies.map((company) => ({
                ...company,
                workflows: mergeWorkflows(company.workflows, newWorkflow),
              }));
            }),
          );
        } catch {}
      },
    }),
    selectCompany: builder.mutation<Workflow, SelectCompanyForDunsRequestBodyDto & { workflowId: string }>({
      query: ({ workflowId, ...data }) => {
        if (data.company && typeof data.company.website === 'string') {
          data.company.website = data.company.website === '' ? undefined : data.company.website;
        }
        return {
          url: `${BASE_API_AUTHORIZATION_URL}/workflows/${workflowId}/select-company`,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: ['User'],
    }),
    sendInvitations: builder.mutation<any, { emails: string[]; aclCompanyId: string; redirectUrl: string }>({
      query: ({ emails, aclCompanyId, redirectUrl }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/companies/${aclCompanyId}/users`,
        method: 'POST',
        data: {
          emails,
          redirectUrl,
        },
      }),
      invalidatesTags: ['Users'],
    }),
    resendInvitation: builder.mutation<any, { invitationId: string }>({
      query: ({ invitationId }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/users/invitations/resend/${invitationId}`,
        method: 'POST',
      }),
    }),
    removeUser: builder.mutation<any, { aclCompanyId: string; userId: string }>({
      query: ({ aclCompanyId, userId }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/companies/${aclCompanyId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    removeUserByEmail: builder.mutation<any, { aclCompanyId: string; email: string }>({
      query: ({ aclCompanyId, email }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/companies/${aclCompanyId}/invitations/${email}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    getUserInvites: builder.query<any, { aclCompanyId: string }>({
      query: ({ aclCompanyId }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/companies/${aclCompanyId}/users`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    updateCompanySettings: builder.mutation<
      IUpdateCompanySettingsResponseDTO,
      { duns: string; data: IUpdateCompanySettingsDTO }
    >({
      query: ({ duns, data }) => ({
        url: `${BASE_API_AUTHORIZATION_URL}/companies/${duns}/settings`,
        method: 'PUT',
        data,
      }),
    }),
    requiredDocs: builder.query<GetRequiredDocumentsResponseDto, GetRequiredDocumentsRequestBodyDto>({
      query: (data) => ({
        url: `${BASE_API_URL}/claims/required-docs`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserImageMutation,
  useGetCompanyQuery,
  useGetReviewStatisticsQuery,
  useUpdateCompanyMutation,
  useGetFavoritesQuery,
  useGetFavoritesIdsQuery,
  useCreateFavoriteMutation,
  useDestroyFavoriteMutation,
  useGetProfileViewsQuery,
  useGetProfileViewsChartQuery,
  useGetSearchImpressionsQuery,
  useGetSearchImpressionsChartQuery,
  useGetSearchClicksQuery,
  useGetSearchClicksChartQuery,
  useGetSearchQuery,
  useAddReviewByUserMutation,
  useDeleteReviewByUserMutation,
  useGetReviewsByUserQuery,
  useGetPublicCompanyReviewsQuery,
  useGetAdminCompanyReviewsQuery,
  useCollectReviewsByAdminMutation,
  useDeleteReplyByAdminMutation,
  useAddDisputeByAdminMutation,
  useDeleteDisputeByAdminMutation,
  useGetLanguagesQuery,
  useAddPersonMutation,
  useEditPersonMutation,
  useEditPersonListMutation,
  useDeletePersonMutation,
  useAddGalleryItemMutation,
  useEditGalleryItemMutation,
  useEditGalleryListMutation,
  useDeleteGalleryItemMutation,
  useAddStatisticMutation,
  useEditStatisticMutation,
  useDeleteStatisticMutation,
  useGetSignatureMutation,
  useReplyReviewMutation,
  useSignUpFormMutation,
  useClaimUploadDocumentsMutation,
  useOnboardingUploadDocumentsMutation,
  useUploadCompanyFormMutation,
  useGetAdditionalDocumentsQuery,
  useVerifyOnboardingCompanyMutation,
  useGetClaimsQuery,
  useCreateClaimMutation,
  useSelectPlanMutation,
  useAddTrackClickMutation,
  // Identity Verification
  useStartIdentityVerificationMutation,
  useCheckIdentityVerificationStatusQuery,
  // Industries
  useGetIndustryByIdQuery,
  useGetSuggestedIndustriesQuery,
  useGetSectorIndustriesQuery,
  useGetSubjectIndustriesQuery,
  useGetIndustriesByParentQuery,
  // Locations
  useGetLocationByIdQuery,
  useGetLocationByCodeQuery,
  useGetSuggestedLocationsQuery,
  useGetAllLocationsQuery,
  useGetLocationsByParentQuery,
  useGetAllCountriesQuery,
  // Search Companies
  useGetSearchByParamsQuery,
  useGetBreadcrumbsQuery,
  useCreateClaimCiecMutation,
  // Pulso
  useGetPulsoTasksQuery,
  useGetSatReportQuery,
  useGetOrderReportQuery,
  useGetBuroReportQuery,
  useRequestSatAuthorizationMutation,
  // Invitations
  useGetUserInvitesQuery,
  useSendInvitationsMutation,
  useResendInvitationMutation,
  useRemoveUserMutation,
  useRemoveUserByEmailMutation,
  // Workflows
  useGetWorkflowQuery,
  useCreateWorkflowMutation,
  useSelectCompanyMutation,
  useUpdateCompanySettingsMutation,
  useActivePlanQuery,
  useRequiredDocsQuery,
  util: { getRunningQueriesThunk },
  endpoints: {
    getUser,
    getCompany,
    getSearchByParams,
    getReviewStatistics,
    // Industries
    getIndustryById,
    getSectorIndustries,
    getIndustriesByParent,
    // Locations
    getLocationById,
    getAllLocations,
    getLocationsByParent,
    // breadcrumbs
    getBreadcrumbs,
    // Workflows
    getWorkflow,
  },
} = api;

export const { useQueryState: useUserQueryState, useQuerySubscription: useUserQuerySubscription } =
  api.endpoints.getUser;

export const USER_CACHE_KEY = 'currentUser';
